import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryCacheProps } from "./hookCommon";
import useNotification from "./useNotification";
import { http } from "../utils";
import { GM_API_SERVER } from "../config";
import { Registrar } from "@peeramid-labs/sdk";
import { Address } from "viem";

const baseQueryKey = ["game", "contract", "instance"];
const ApiUrl = GM_API_SERVER;

export const useGameMasterBackend = () => {
    const notification = useNotification();
    const qClient = useQueryClient();

    const useMyProposalId = ({ rankifyInstanceAddress, turnId, gameId }:
        { rankifyInstanceAddress: Address, turnId: number, gameId: number }) => useQuery({
            queryKey: ["GMBackend", "myProposalId", gameId, turnId],
            queryFn: async () => {
                try {
                    return await http({
                        method: "GET",
                        url: `${ApiUrl}/player/proposal/id/${rankifyInstanceAddress}/${gameId}/${turnId}`,
                    }).then((r) => r.data)
                } catch (error: any) {
                    console.error(error);
                    notification(error?.message, "error");
                }
            },
            ...queryCacheProps,
            refetchInterval: false,
        });


    const signUp = useMutation({
        mutationFn: async ({ username }: { username: string }) => {
            const response = await http({
                method: "POST",
                url: `${ApiUrl}/registrar/sign`,
                data: { username },
            });

            const data = response.data;

            return data as {
                signature: Address;
                message: ReturnType<Registrar["getRegistrarMessage"]>;
            };
        },

        onSuccess: () => {
            qClient.refetchQueries({ queryKey: baseQueryKey });
        },
    });

    const submitProposalMutation = useMutation({
        mutationFn: async ({ rankifyInstanceAddress, proposal, gameId, turn }: { rankifyInstanceAddress: Address, proposal: string, gameId: string, turn: string }) => {
            if (!gameId) throw new Error("No GameId");
            if (!turn) throw new Error("turn number");
            return await http({
                method: "POST",
                url: `${ApiUrl}/player/proposal/${rankifyInstanceAddress}/${gameId}/${turn}`,
                data: { proposal },
            });
        },
        onError: (e: any) => {
            console.error(e);
            notification(e?.message, "error");
        },
    });

    const submitVotesMutation = useMutation({
        mutationFn: async ({ rankifyInstanceAddress, vote, gameId }: { rankifyInstanceAddress: Address, vote: number[], gameId: string }) => {
            if (!gameId) throw new Error("No GameId");
            return await http({
                method: "POST",
                url: `${ApiUrl}/player/vote/${rankifyInstanceAddress}/${gameId}`,
                data: { vote },
            });
        },
        onError: (e: any) => {
            console.error(e);
            notification(e?.message, "error");
        },
    });


    return {
        useMyProposalId,
        submitProposalMutation,
        submitVotesMutation,
        signUp,
    };
};

export default useGameMasterBackend;
