import { abis, getArtifact, MultipassBase } from "@peeramid-labs/sdk";
import { Web3ProviderInterface } from "../types";
import useNotification from "./useNotification";
import { processError } from "../utils/processError";
import { Address, ContractFunctionArgs, hexToString, PublicClient, WalletClient, zeroAddress, zeroHash } from "viem";
import { type WriteContractErrorType } from '@wagmi/core'
import { useEffect, useState } from "react";
import { useReadContract, useWriteContract, useWaitForTransactionReceipt } from "wagmi";
import { DOMAIN_NAME } from "../config";

export type useMultipassProps = {
    chainId: number,
    publicClient: PublicClient,
    walletClient: WalletClient,
    account: Address,
}

export const useMultipass = (props: useMultipassProps) => {
    const [username, setUsername] = useState<string | null>(null);
    const [isUsernameFetched, setIsUsernameFetched] = useState<boolean>(false);
    const notification = useNotification();

    //**************Multipass resolve record*****************
    const multipassBase = new MultipassBase({
        chainId: props.chainId,
        publicClient: props.publicClient,
    });

    const multipassContractAddress: Address = getArtifact(props.chainId, "Multipass").address;

    const resolveRecordReadContract = useReadContract({
        abi: abis.MultipassAbi,
        address: multipassContractAddress,
        functionName: "resolveRecord",
        args: [
            multipassBase.formQueryByAddress({
                address: props.account,
                domainName: DOMAIN_NAME,
            }),
        ],
    }
    );

    useEffect(() => {
        if (resolveRecordReadContract.isSuccess) {
            if (!resolveRecordReadContract.data || !resolveRecordReadContract.data[1].name
                || !resolveRecordReadContract.data[0]) {
                setUsername("");
            } else {
                setUsername(hexToString(resolveRecordReadContract.data[1].name, { size: 32 }));
            }
        }
    }, [resolveRecordReadContract.isSuccess, resolveRecordReadContract.isFetching]);

    useEffect(() => {
        if (resolveRecordReadContract.isError) {
            processError(resolveRecordReadContract.error, abis.MultipassAbi, notification);
            setUsername("");
        }
    }, [resolveRecordReadContract.isError]);

    useEffect(() => {
        if (username !== null) {
            setIsUsernameFetched(true);
        }
    }, [username]);

    //**************Multipass signup*****************
    const registerWriteContract = useWriteContract();

    const signUp = async ({
        message,
        registrarSignature,
    }: {
        message: ContractFunctionArgs<typeof abis.IMultipassAbi, "payable", "register">[0],
        registrarSignature: Address,
    }) => {
        if (!props.chainId) throw new Error("No chain selected");
        const emptyUserQuery: ContractFunctionArgs<typeof abis.IMultipassAbi, "payable", "register">[2] = {
            name: zeroHash,
            id: zeroHash,
            domainName: zeroHash,
            wallet: zeroAddress,
            targetDomain: zeroHash,
        };

        registerWriteContract.writeContract({
            abi: abis.MultipassAbi,
            address: multipassContractAddress,
            functionName: "register",
            args: [message, registrarSignature, emptyUserQuery, zeroHash],
        }, {
            onError: (error: WriteContractErrorType) => {
                processError(error, abis.MultipassAbi, notification);
                registerWriteContract.reset();
            }
        });
    };


    const { isLoading: isSignUpTransactionLoading, isSuccess: isSignUpTransactionSuccess, isError: isSignUpTransactionError, error: signupTransactionError } = useWaitForTransactionReceipt({
        hash: registerWriteContract.data,
    })

    useEffect(() => {
        if (isSignUpTransactionSuccess) {
            resolveRecordReadContract.refetch();
            notification("Username successfully registered", "success");
        } else if (isSignUpTransactionError) {
            processError(signupTransactionError, abis.MultipassAbi, notification);
            console.error(signupTransactionError);
        }
    }, [isSignUpTransactionSuccess, isSignUpTransactionError]);

    return {
        username,
        isUsernameFetched,
        isSignUpPending: registerWriteContract.isPending || isSignUpTransactionLoading,
        signUp,
    };

};

export const generateInputProps = ({
    web3ProviderContext, account
}: {
    web3ProviderContext: Web3ProviderInterface;
    account?: Address | null;
}) => {
    if (!web3ProviderContext.currentChain) {
        throw new Error("Current chain not initialized");
    }
    if (!web3ProviderContext.account) {
        throw new Error("Account not initialized");
    }

    return {
        chainId: web3ProviderContext.currentChain.id,
        publicClient: web3ProviderContext.publicClient,
        walletClient: web3ProviderContext.walletClient,
        account: account ?? web3ProviderContext.account
    } as useMultipassProps;
};